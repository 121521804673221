import {
  collection,
  connectFirestoreEmulator,
  getDocs,
  query,
  where,
  Timestamp,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  increment,
  writeBatch,
} from 'firebase/firestore'

import { db, auth } from './firebase.config'
import { connectAuthEmulator } from 'firebase/auth'
import { addMinutes, parseISO, format, startOfDay, endOfDay } from 'date-fns'
import { de, enGB } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';

// check if on development mode
export const checkIfDevelopment = () => {
  return process.env.NODE_ENV === 'development'
}

/**
 * get project id from html snippet.
 * @returns string
 */
export const getProjectId = () => {
  const holderEl = document.getElementById('mmi-booking-form')
  return holderEl.dataset.projectid
}

export const checkIfApartment = () => {
  const holderEl = document.getElementById('mmi-booking-form')
  return holderEl.dataset.apartment
}

export const getApartmentId = () => {
  const holderEl = document.getElementById('mmi-booking-form')
  return holderEl.dataset.apartmentid
}

export const getLanguage = () => {
  const holderEl = document.getElementById('mmi-booking-form')
  return holderEl.dataset.language
}

export const getProjectData = async projectId => {
  const docRef = doc(db, 'projects', projectId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    console.log('No such project!')
  }
}

export const getAvailableDays = async projectId => {
  // add 1 day to starting day
  const isApartment = checkIfApartment() === 'yes'
  const apartmentId = getApartmentId()
  const startTime = new Date()
  const startDate = Timestamp.fromDate(startTime)
  const collectionRef = collection(db, 'projects', projectId, 'schedules')
  // Fetch project settings
  const settings = await getProjectSettings(projectId);
  const visibleFor = settings.visible_for || 14;

  // Convert Firestore Timestamp to JavaScript Date
  const startDateJs = startDate.toDate();

  // Calculate maximum date
  const maxDate = new Date(startDateJs);
  maxDate.setDate(startDateJs.getDate() + visibleFor);
  const maxTimestamp = Timestamp.fromDate(maxDate);

  let filterQuery = query(
    collectionRef,
    where('to', '>=', startDate),
    where('to', '<=', maxTimestamp), // Add this line
    where('is_apartment', '==', false)
  )

  if (isApartment) {
    filterQuery = query(
      collectionRef,
      where('to', '>=', startDate),
      where('to', '<=', maxTimestamp), // Add this line
      where('apartmentUID', '==', apartmentId)
    )
  }

  const querySnapshot = await getDocs(filterQuery)

  const hoursByDate = []
  querySnapshot.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots

    hoursByDate.push({
      day: doc.id,
      ...doc.data(),
    })
  })

  return hoursByDate
}

export const getProjectSettings = async projectId => {
  const docRef = doc(db, 'projects', projectId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const { settings } = docSnap.data()
    return settings
  } else {
    console.log('No such document!')
  }
}

export const getProjectManagerId = async projectId => {
  const docRef = doc(db, 'projects', projectId)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    const { manager } = docSnap.data()
    return manager
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!')
  }
}

export const getProjectManagerData = async projectId => {
  return getProjectManagerId(projectId).then(async managerId => {
    const docRef = doc(db, 'users', managerId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!')
    }
  })
}

export const getBrokerData = async brokerId => {
  const docRef = doc(db, 'users', brokerId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!')
  }
}

export const getProjectBrokerData = async (projectId, brokerId) => {
  const docRef = doc(db, 'projects', projectId, 'brokers', brokerId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log('No such broker found!')
  }
}

export const updateAppointmentCount = async (
  projectId,
  time,
  cancel = false
) => {
  const timeInSeconds = time.seconds.toString()
  const incrementNr = cancel ? -1 : 1
  const docRef = collection('projects/' + projectId + '/booking_counts').doc(
    timeInSeconds
  )

  await updateDoc(docRef, {
    counter: increment(incrementNr),
  })
}

// create a function to check if use has already created an appointment
// check if email and phone number is already in use
// check if it has an appointment in the last 24 hours from appointment create time

export const checkIfUserHasAppointment = async (projectId, email, phone) => {
  const collectionRef = collection(db, 'projects', projectId, 'appointments')
  const oneDayInMilliseconds = 86400000
  const now = new Date()
  const yesterday = new Date(now - oneDayInMilliseconds)

  const filterQuery = query(collectionRef, where('createdAt', '>=', yesterday))

  // now check if at least one of the documents has the same email or phone number
  const querySnapshot = await getDocs(filterQuery)
  let hasAppointment = false
  querySnapshot.forEach(doc => {
    const { email: docEmail, tel: docPhone } = doc.data()
    if (docEmail === email || docPhone === phone) {
      hasAppointment = true
    }
  })

  return hasAppointment

  // return true if user has an appointment
  // const querySnapshot = await getDocs(filterQuery)
  // return querySnapshot.size > 0
}

export const createAppointment = async (projectId, values, checkedEnglish) => {
  const collectionRef = collection(db, 'projects', projectId, 'appointments')
  const apartmentId = getApartmentId()
  const isApartment = checkIfApartment() === 'yes'
  //const endTime = addMinutes(parseISO(values.time), parseInt(values.intervals))
  // split values.time on _ to get first part
  const time = values.time.split('_')[0]
  const hour = time.split('T')[1]
  values.time = parseISO(time)
  values.endTime = addMinutes(values.time, parseInt(values.intervals))
  values.projectId = projectId
  values.language = checkedEnglish ? 'en' : 'de'
  values.hour = hour
  values.date = format(values.time, 'yyyy-MM-dd')
  values.email = values.email.toLowerCase().trim();

  // add createAt timestamp
  values.createdAt = Timestamp.fromDate(new Date())

  // get apartment data by apartmentId as slug
  isApartment &&
    (await getApartmentDataByUID(projectId, apartmentId).then(results => {
      values.apartment = results
    }))

  // values.endTime = format(endTime, "yyyy-MM-dd'T'HH:mm")
  await getProjectManagerData(projectId).then(results => {
    values = { ...values, manager: results }
  })

  await getBrokerData(values.brokerId).then(results => {
    const { phone, email, name } = results
    values = { ...values, broker: { phone, email, name } }
  })

  await getProjectSettings(projectId).then(settings => {
    const {
      google_calendarid,
      meeting_point: globalMeetingPoint,
      website,
    } = settings
    const meeting_point = values.meeting_point || globalMeetingPoint

    values = { ...values, google_calendarid, meeting_point, website }

    // check if is apartment and values.meeting_point is set and has a value
  })

  const docRef = await addDoc(collectionRef, values)

  return docRef.id
}

export const isTimeFullyBooked = async (projectId, time, limit) => {
  const [dateTime] = time.split('_');
  const [date, hour] = dateTime.split('T');
  
  const apartmentId = getApartmentId();
  const isApartment = checkIfApartment() === 'yes';

  // Create the reference to the collection
  let collectionRef;
  if (isApartment) {
    collectionRef = collection(
      db,
      'projects',
      projectId,
      'apartments',
      apartmentId,
      'booking_counts'
    );
  } else {
    collectionRef = collection(db, 'projects', projectId, 'booking_counts');
  }

  const queryRef = query(
    collectionRef,
    where('date', '==', date),
    where('hour', '==', hour)
  );

  const querySnapshot = await getDocs(queryRef);

  if (querySnapshot.empty) {
    return false;
  } else {
    const { counter } = querySnapshot.docs[0].data();
    return counter >= limit;
  }
};



export const getDailyBookedSlotsTest = async (projectId, date, apartmentId = null) => {

  const berlinTime = utcToZonedTime(new Date(date), 'Europe/Berlin');
  const berlinDate = format(berlinTime, 'yyyy-MM-dd');
  let collectionRef = collection(db, 'projects', projectId, 'appointments')
  // Format the received date to 'YYYY-MM-DD'
  let formattedDate = format(date, 'yyyy-MM-dd');
  
  let filterQuery = query(
    collectionRef,
    where('date', '==', berlinDate)
  );

  if (apartmentId) {

    filterQuery = query(
      collectionRef,
      where('apartment.id', '==', apartmentId),
      where('date', '==', berlinDate)
    );
  }

  const querySnapshot = await getDocs(filterQuery);
  const bookedHours = [];

  querySnapshot.forEach(doc => {
    const { date, hour, brokerId, canceled } = doc.data();
    if (canceled) return;
    bookedHours.push({ date, hour, brokerId });
  });

  return bookedHours
}


/**
 *
 * @param {*} projectId
 * @param {*} date Using dd-mm-YYYY format.
 */
export const getDailyBookedSlots = async (
  projectId,
  date,
  apartmentId = null
) => {
  const berlinTime = utcToZonedTime(new Date(date), 'Europe/Berlin');
  const berlinDate = format(berlinTime, 'yyyy-MM-dd');
  let collectionRef = collection(db, 'projects', projectId, 'appointments')

  // check firebase time field if is on same day as date which is in dd-mm-yyyy format
  // canceled is not true
  let filterQuery = query(
    collectionRef,
    where('date', '==', berlinDate)
   // where('canceled', '!=', true)
  )

  if (apartmentId) {
    const { group } = await getApartmentDataByUID(projectId, apartmentId);

    if (group) {
      filterQuery = query(
        collectionRef,
        where('apartment.group', '==', group),
        where('date', '==', berlinDate)
        // where('canceled', '!=', true)
      );
    } else {
      filterQuery = query(
        collectionRef,
        where('apartment.id', '==', apartmentId),
        where('date', '==', berlinDate)
        // where('canceled', '!=', true)
      );
    }
  }


  const querySnapshot = await getDocs(filterQuery)
  const bookedHours = []
  querySnapshot.forEach(doc => {
    const { date, hour, brokerId, canceled } = doc.data();
    if (canceled) return;
    bookedHours.push({ date, hour, brokerId });
  })

  console.log('bookedHours: ', bookedHours);

  return bookedHours
}

export const cancelAppointments = async (projectId, appointmentIds) => { 
  // Start a new batch
  const batch = writeBatch(db);

  // Map over appointmentIds and update each document in a batch
  appointmentIds.forEach(appointmentId => {
    // Document references are created with the db reference
    const docRef = doc(db, `projects/${projectId}/appointments/${appointmentId}`);
    // Updates are added to the batch with the docRef and new data
    batch.update(docRef, { canceled: true });
  });

  // Commit the batch, this will return a promise that resolves when all updates are written
  return batch.commit()
    .then(() => ({
      success: true,
      failedCancellations: []
    }))
    .catch(error => {
      console.error(`Failed to cancel appointments: ${error.message}`);
      return {
        success: false,
        failedCancellations: appointmentIds
      };
    });
};


export const formatAppointmentDate = (appointment) => {
  // Convert the date string to a Date object
  const appointmentDate = parseISO(appointment.date);

  console.log('appointmentDate: ', appointmentDate);

  // Select locale based on appointment language
  const locale = appointment.language === 'de' ? de : enGB;

  // Format the date in the selected locale
  const formattedDate = format(appointmentDate, 'PP', { locale });

  // Combine the formatted date and the hour
  const formattedDateTime = `${formattedDate}, ${appointment.hour}`;

  return formattedDateTime;
};

export const fetchAppointmentsByEmail = async (projectId, email) => {
  // flatten the email
  email = email.toLowerCase().trim();
  const appointmentsRef = collection(db, 'projects/' + projectId + '/appointments');
  const q = query(appointmentsRef, where('email', '==', email));

  const querySnapshot = await getDocs(q);
  let appointments = [];
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    // Convert the timestamp to a JavaScript Date object
    const appointmentTime = data.time.toDate();

    // if 'canceled' field doesn't exist or if it exists but not true, add to the results
    // and if the appointment time is in the future
    if (!data.canceled && appointmentTime > new Date()) {
      appointments.push({...data, id: doc.id});
    }
  });

  return appointments;
};

export const checkAppointmentExists = async (projectId, appointmentId) => {
  const docRef = doc(db, 'projects/' + projectId + '/appointments', appointmentId);
  const docSnap = await getDoc(docRef);

  return docSnap.exists()
};





export const getExtraAcceptances = async projectId => {
  const collectionRef = collection(
    db,
    'projects',
    projectId,
    'acceptance_fields'
  )

  const querySnapshot = await getDocs(collectionRef)
  return querySnapshot.docs.map(doc => {
    return { ...doc.data(), id: doc.id }
  })
}

export const getCustomFields = async projectId => {
  const collectionRef = collection(db, 'projects', projectId, 'custom_fields')

  const querySnapshot = await getDocs(collectionRef)
  return querySnapshot.docs.map(doc => {
    return { ...doc.data(), id: doc.id }
  })
}

// get apartment data by apartment_slug in a project
// return the first item of the found array
export const getApartmentDataBySlug = async (projectId, apartmentSlug) => {
  const collectionRef = collection(db, 'projects', projectId, 'apartments')
  const filterQuery = query(
    collectionRef,
    where('apartment_slug', '==', apartmentSlug)
  )
  const querySnapshot = await getDocs(filterQuery)
  return querySnapshot.docs
    .map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    .shift()
}

export const getApartmentDataByUID = async (projectId, apartmentUID) => {
  const docRef = doc(db, 'projects', projectId, 'apartments', apartmentUID)
  const docSnap = await getDoc(docRef)
  return docSnap.exists()
    ? { ...docSnap.data(), id: docSnap.id }
    : console.log('No such documenta!')
}

// add doc at notifications collection with email and projectId
export const addNotification = async (projectId, email, apartmentId) => {
  const collectionRef = collection(db, 'notifications')
  
  let docData = {
    email,
    projectId
  };
  
  // Add the apartmentId if it's defined
  if (apartmentId) {
    docData.apartmentId = apartmentId;
  }

  const docRef = await addDoc(collectionRef, docData);
  
  return docRef.id;
}

export const allSlotsBooked = (timeSlots) => {
  return timeSlots.every(daySlot => 
    daySlot.allDaySlots.every(slot => slot.booked)
  );
};


// if (checkIfDevelopment()) {
//   connectAuthEmulator(auth, 'http://localhost:9099')
//   connectFirestoreEmulator(db, 'localhost', 8080)
// }
