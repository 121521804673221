import { useEffect, useState } from 'react'
import {
  getProjectData,
  getAvailableDays,
  getDailyBookedSlots,
  getProjectBrokerData,
  getApartmentId,
  checkIfApartment,
  getApartmentDataBySlug,
  getApartmentDataByUID,
  getDailyBookedSlotsTest,
} from '../lib/firebase'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import { isFuture, format, isEqual, isValid } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import de from 'date-fns/locale/de'
import { reorderArrayByDateProperty } from '../lib/utilities'
import _ from 'lodash'

export const useProjectData = projectId => {
  const [projectData, setProjectData] = useState(null)
  useEffect(() => {
    getProjectData(projectId).then(result => {
      setProjectData(result)
    })
  }, [projectId])

  return projectData
}

const secondsToDate = seconds => {
  return new Date(seconds * 1000)
}

const getDaySlots = schedule => {
  const {
    from,
    to,
    date,
    has_break,
    breakFrom,
    breakTo,
    brokerId,
    intervals,
    perSlot,
  } = schedule
  const fromTime = new Date(from.seconds * 1000)
  const toTime = new Date(to.seconds * 1000)
  const slotTimesBeforeBreak = has_break
    ? eachMinuteOfInterval(
        {
          start: fromTime,
          end: secondsToDate(breakFrom.seconds - parseInt(intervals) * 60),
        },
        {
          step: parseInt(intervals),
        }
      )
    : []

  const slotTimesAfterBreak =
    to.seconds > breakFrom.seconds && has_break
      ? eachMinuteOfInterval(
          {
            start: secondsToDate(breakTo.seconds),
            end: toTime,
          },
          {
            step: parseInt(intervals),
          }
        )
      : []

  const slotTimes = has_break
    ? slotTimesBeforeBreak.concat(slotTimesAfterBreak)
    : eachMinuteOfInterval(
        {
          start: fromTime,
          end: toTime,
        },
        {
          step: parseInt(intervals),
        }
      )

  return slotTimes.filter(slot => {
    return isFuture(slot)
  })
}

export const useTimeSlots = projectId => {
  const [timeSlots, setTimeSlots] = useState([])
  const apartmentId = getApartmentId()
  const isApartment = checkIfApartment()
  const [loading, setLoading] = useState(true)
  useEffect(() => {

    const listSlots = async projectId => {
      const schedules = await getAvailableDays(projectId)
      console.log('schedules', schedules)
      const groupByDay = _.groupBy(schedules, ({ date }) =>
        format(new Date(date.seconds * 1000), 'MM-dd-yyyy')
      )

      return Promise.all(
        Object.entries(groupByDay).map(async ([day, daySchedules]) => {
          const date = new Date(daySchedules[0].date.seconds * 1000)
          const formattedDate = format(date, 'EEEEEE, MMM dd', { locale: de })
          let allDaySlots = []

          for (const schedule of daySchedules) {
            let { per_slot } =
              isApartment === 'yes'
                ? await getApartmentDataByUID(projectId, apartmentId)
                : await getProjectBrokerData(projectId, schedule.brokerId)
            per_slot = schedule.per_slot || per_slot

            const slots = getDaySlots(schedule)
              .map(slot => ({
                ...schedule,
                slot,
                per_slot: per_slot || 1,
                meeting_point: schedule.meeting_point || false,
                description: schedule.description || '',
              }))
              .slice(0, -1) // Exclude last slot

            allDaySlots.push(...slots)
          }

          return {
            day: formattedDate,
            date,
            allDaySlots,
          }
        })
      )
    }

    const isWithinInterval = (target, booked, interval) => {
      const targetTime = new Date(`1970-01-01T${target}:00Z`).getTime()
      const bookedTime = new Date(`1970-01-01T${booked}:00Z`).getTime()
      return Math.abs(targetTime - bookedTime) < interval * 60 * 1000
    }

    const isExactMatch = (target, bookedTimes) => {
      return bookedTimes.some(({ hour }) => hour === target)
    }

    listSlots(projectId).then(allDays => {

      Promise.all(
        allDays.map(({ date, allDaySlots }) => {
          return getDailyBookedSlots(projectId, date, apartmentId)
            .then(bookedTimes => {
              const allBerlinHourSlots = allDaySlots.map(dayslot =>
                format(utcToZonedTime(dayslot.slot, 'Europe/Berlin'), 'HH:mm')
              )

              return allDaySlots.map(dayslot => {
                const { intervals, slot, brokerId } = dayslot
                const berlinHourSlot = format(
                  utcToZonedTime(slot, 'Europe/Berlin'),
                  'HH:mm'
                )
                const bookingsForSlot = bookedTimes.filter(
                  ({ date: bookedDate, hour, brokerId: bId }) => {
                    
                    const sameDate =
                      bookedDate ===
                      format(
                        utcToZonedTime(slot, 'Europe/Berlin'),
                        'yyyy-MM-dd'
                      )
                      const withinInterval = brokerId === bId && isWithinInterval(
                        berlinHourSlot,
                        hour,
                        intervals ? parseInt(intervals) : 45
                      )
                      
                    const exactMatch = isExactMatch(hour, allBerlinHourSlots)
                    return sameDate && (withinInterval || exactMatch)
                  }
                )
                const isBooked =
                  bookingsForSlot.length >= parseInt(dayslot.per_slot)
                return { ...dayslot, booked: isBooked }
              })
            })
            .then(finalDaySlots => {
              const formattedDate = format(new Date(date), 'EEEEEE, MMM dd', {
                locale: de,
              })
              return { day: formattedDate, allDaySlots: finalDaySlots }
            })
        })
      ).then(result => {
        setTimeSlots(result)
        setLoading(false)
      })
    })
  }, [apartmentId, isApartment, projectId])

  return { timeSlots, loading }
}